<template>
  <CreateUpdateTemplate
    :customClass="'leave-update'"
    v-if="getPermission('leave:update')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Update Leave</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save Leave
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="leaveForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid>
          <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 70vh; position: relative"
          > -->
          <v-row>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="200">Engineer</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Engineer"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="userList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="leaveCreate.engineer"
                      label="Engineer"
                      solo
                      flat
                      item-color="cyan"
                      item-text="display_name"
                      item-value="id"
                      hide-details
                      :rules="[
                        validateRules.required(leaveCreate.engineer, 'Engineer')
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title
                            v-html="'No Engineer(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar>
                          <v-img
                            :lazy-src="$defaultProfileImage"
                            :src="$assetAPIURL(item.profile_logo)"
                            aspect-ratio="1"
                            class="margin-auto grey lighten-5 custom-grey-border"
                            transition="fade-transition"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >#{{ item.barcode }}</v-list-item-title
                          >
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{ item.display_name }}</v-list-item-title
                          >
                        </v-list-item-content>
                        <v-list-item-action class="align-self-center">
                          <v-list-item-subtitle
                            class="text-lowercase pb-2 font-weight-500 font-size-14"
                            >{{ item.primary_email }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            class="text-lowercase font-weight-500 font-size-14"
                            >{{ item.primary_phone }}</v-list-item-subtitle
                          >
                        </v-list-item-action>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Leave Type</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Leave Type"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="leaveTypeList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="leaveCreate.leave_type"
                      label="Leave Type"
                      solo
                      flat
                      item-color="cyan"
                      hide-details
                      :rules="[
                        validateRules.required(
                          leaveCreate.leave_type,
                          'Leave Type'
                        )
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Leave Type Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Team Email ID</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Team Email ID"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.trim="leaveCreate.team_email_id"
                      dense
                      filled
                      label="Team Email ID"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      :rules="[
                        validateRules.required(
                          leaveCreate.team_email_id,
                          'Team Email ID'
                        ),
                        validateRules.validEmail(
                          leaveCreate.team_email_id,
                          'Team Email ID'
                        ),
                        validateRules.minLength(
                          leaveCreate.team_email_id,
                          'Team Email ID',
                          1
                        ),
                        validateRules.maxLength(
                          leaveCreate.team_email_id,
                          'Team Email ID',
                          100
                        )
                      ]"
                    ></v-text-field>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="150">Leave number</td>
                  <td
                    class="font-weight-700 font-size-16 pb-4"
                    colspan="3"
                    width="150"
                  >
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        readonly
                        v-model.trim="leaveCreate.barcode"
                        label="Leave number"
                        solo
                        flat
                        hide-details
                        class="remove-border cursor-default"
                      ></v-text-field>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="150">Leave duration</td>
                  <td class="font-weight-700 font-size-16 pb-4" colspan="3">
                    <v-radio-group
                      v-model="leaveCreate.leave_duration"
                      mandatory
                      row
                      hide-details
                      class="m-0"
                    >
                      <v-radio
                        label="Full Day"
                        value="full-day"
                        color="cyan"
                      ></v-radio>
                      <v-radio
                        label="Half Day"
                        value="half-day"
                        color="cyan"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="150">Start Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Start Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      solo
                      mandatory
                      :defaultDate.sync="leave_start_date"
                      :pageLoading.sync="pageLoading"
                      placeholder="Start Date"
                      v-model="leaveCreate.leave_start_date"
                    ></DatePicker>
                  </td>
                  <td class="font-size-16 pb-2 px-4" width="150">
                    <template v-if="leaveCreate.leave_duration == 'full-day'">
                      End Date
                    </template>
                  </td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="End Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <template v-if="leaveCreate.leave_duration == 'full-day'">
                      <DatePicker
                        solo
                        mandatory
                        :defaultDate.sync="leave_end_date"
                        :pageLoading.sync="pageLoading"
                        placeholder="End Date"
                        v-model="leaveCreate.leave_end_date"
                      ></DatePicker>
                    </template>
                  </td>
                </tr>
                <tr v-if="leaveCreate.leave_duration == 'half-day'">
                  <td class="font-size-16 pb-2" width="150">Start Time</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Start Time"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <TimePicker
                      solo
                      mandatory
                      :defaultTime.sync="leave_start_time"
                      :pageLoading.sync="pageLoading"
                      placeholder="Start Time"
                      v-model="leaveCreate.leave_start_time"
                    ></TimePicker>
                  </td>
                  <td class="font-size-16 pb-2 px-4" width="150">End Time</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="End Time"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <TimePicker
                      solo
                      mandatory
                      :defaultTime.sync="leave_end_time"
                      :pageLoading.sync="pageLoading"
                      placeholder="End Time"
                      v-model="leaveCreate.leave_end_time"
                    ></TimePicker>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="12" class="py-0">
              <editor v-model="leaveCreate.reason" />
            </v-col>
            <v-col md="12">
              <FileTemplate
                allowUpload
                :attachments.sync="attachments"
                v-on:file:updated="updateAttachment"
              ></FileTemplate>
            </v-col>
          </v-row>
          <!-- </perfect-scrollbar> -->
        </v-container>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import TimePicker from "@/view/pages/partials/Timepicker.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CLEAR_ERROR, PUT, GET } from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import moment from "moment-timezone";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "leave-update",
  title: "Update Leave",
  data() {
    return {
      leave: null,
      pageLoading: false,
      barcodeDialog: false,
      leave_start_date: null,
      leave_end_date: null,
      leave_start_time: null,
      leave_end_time: null,
      barcodeSetting: new Object(),
      leaveTypeList: new Array(),
      userList: new Array(),
      attachments: new Array(),
      leaveCreate: new Object({
        engineer: null,
        leave_duration: null,
        leave_type: null,
        leave_start_date: null,
        leave_end_date: null,
        leave_start_time: null,
        leave_end_time: null,
        reason: null,
        team_email_id: null,
        attachments: []
      })
    };
  },
  components: {
    DatePicker,
    TimePicker,
    FileTemplate,
    CreateUpdateTemplate,
    editor: TinyMCE
  },
  methods: {
    updateAttachment(param) {
      this.leaveCreate.attachments = param;
    },
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leave/options"
        })
        .then(({ data }) => {
          _this.userList = data.users;
          _this.leaveTypeList = data.leave_type;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateOrCreate() {
      const _this = this;
      if (!_this.$refs.leaveForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        engineer: _this.leaveCreate.engineer || null,
        leave_duration: _this.leaveCreate.leave_duration || null,
        leave_type: _this.leaveCreate.leave_type || null,
        leave_start_date: _this.leaveCreate.leave_start_date || null,
        leave_end_date: _this.leaveCreate.leave_end_date || null,
        leave_start_time: _this.leaveCreate.leave_start_time
          ? moment(
              _this.leaveCreate.leave_start_date +
                " " +
                _this.leaveCreate.leave_start_time
            ).format("HH:MM")
          : null,
        leave_end_time: _this.leaveCreate.leave_end_time
          ? moment(
              _this.leaveCreate.leave_end_date +
                " " +
                _this.leaveCreate.leave_end_time
            ).format("HH:MM")
          : null,
        reason: _this.leaveCreate.reason || null,
        team_email_id: _this.leaveCreate.team_email_id || null,
        attachments: _this.leaveCreate.attachments || []
      });

      _this.$store
        .dispatch(PUT, {
          url: "leave/" + _this.leave,
          data: formData
        })
        .then(({ data }) => {
          _this.$router.push(
            _this.getDefaultRoute("leave.detail", {
              params: {
                id: data.id
              }
            })
          );
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getLeave() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leave/" + _this.leave
        })
        .then(({ data }) => {
          _this.leaveCreate = new Object({
            barcode: data.barcode,
            engineer: data.engineer ? data.engineer.engineer : null,
            leave_duration: data.duration,
            leave_type: data.type_update,
            leave_start_date: data.start_date,
            leave_end_date: data.end_date,
            leave_start_time: data.start_time,
            leave_end_time: data.end_time,
            reason: data.reason,
            status: data.status,
            team_email_id: data.email,
            attachments: []
          });
          _this.leave_start_date = data.start_date;
          _this.leave_end_date = data.end_date;
          if (data.start_time) {
            _this.leave_start_time = moment(
              data.start_date + " " + data.start_time
            ).format("hh:mm A");
          }
          if (data.end_time) {
            _this.leave_end_time = moment(
              data.end_date + " " + data.end_time
            ).format("hh:mm A");
          }
          _this.attachments = data.attachments;
        })
        .catch(error => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    }
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Leave", route: "leave" },
      { title: "Update" }
    ]);

    _this.leave = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.leave <= 0) {
      _this.goBack();
    }

    _this.getLeave();
  }
};
</script>
